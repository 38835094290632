/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/subseven/tongay@master/jquery.js
 * - /gh/subseven/tongay@master/bootstrap.min.js
 * - /gh/subseven/tongay@master/bootstrap.slide-menu.min.js
 * - /gh/subseven/tongay@master/modernizr.custom.min.js
 * - /gh/subseven/tongay@master/parallax.min.js
 * - /gh/subseven/tongay@master/jquery.ui.totop.min.js
 * - /gh/subseven/tongay@master/sidebar-transitions.min.js
 * - /gh/subseven/tongay@master/jquery.fancybox.pack.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
